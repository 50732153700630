import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCheckbox,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonSelect,
    IonTitle,
    IonSelectOption,
    IonNote,
} from '@ionic/react';
import Toolbar from '../components/ToolBar';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom'
import React, { useState } from 'react';


const prod_bearer = "OTgyMjk5ODgwMDQyOtWodf4y57Nlx259CqAXBltpeNRU"
const test_bearer = "NjUzNjc1NjcwNjU3Oso1Qp9mbWiRk4Yd1BtKxo5KstnW"



function formatDate(dateObj: Date) {
    var month = dateObj.getUTCMonth() + 1;
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    return year + '-' + month + '-' + day + ' ' + '0:00:00'
}

function createOrderNumber(effectiveOrderDate: Date) {
    // 1 + YYYYMMDD + SSSSS + MSSSS
    var year = effectiveOrderDate.getUTCFullYear();
    var month = effectiveOrderDate.getUTCMonth() + 1;
    var day = effectiveOrderDate.getUTCDate();
    var seconds = effectiveOrderDate.getSeconds();
    var milli = effectiveOrderDate.getMilliseconds();

    return "1" + year + month + day + seconds + milli
}

function pad(unitOfTime: any) {
    if (unitOfTime <= 9) {
        return `0${unitOfTime}`
    }

    return unitOfTime;
}

async function createSpreadsheetRow(data: any, orderSubmitted: Date, effectiveOrderDate: Date, orderNumber: string) {

    const payload = {
        organization: data['ordererOrganization'],
        clinicianName: data['ordererName'],
        clinicianEmail: data['ordererEmail'],
        inventorySubset: data['inventorySubset'],
        date: orderSubmitted.toLocaleString(),
        effectiveOrderDate: formatDate(effectiveOrderDate),
        kit: data['kitTypeSelect'],
        warehouseComments: data['warehouseComments'],
        carrierComments: data['carrierComments'],
        patientName: data['patientName'],
        patientEmail: data['patientEmail'],
        phoneNumber: data['patientPhoneNumber'],
        address1: data['patientAddress1'],
        address2: data['patientAddress2'],
        city: data['patientCity'],
        province: data['patientProvince'],
        zip: data['patientPostalCode'],
        patientId: data['patientId'],
        serviceType: data['shippingSpeedSelect'],
        hrsKitNumber: '',  //reference 1
        supportTicketNumber: '',
        orderNumber: createOrderNumber(effectiveOrderDate), //reference 2
        orderType: 'Order',
        itemForRepair: '',
        defectReason: '',
        virtualInstall: data['virtualInstall'],
        returnsCoordination: ''
    }

    const webHookURL = "https://hooks.zapier.com/hooks/catch/2277921/33k61y7/"

    const res = await fetch(webHookURL, {
        method: "POST",
        body: JSON.stringify(payload)
    });

    if (!res.ok) {
        const message = `An error has occured: ${res.status}`;
        throw new Error(message);
    }

    return await res.json();
}

async function createMediohOrder(data: any, orderSubmitted: Date, orderNumber: string) {

    /* Format data for Medioh Request */
    var month = orderSubmitted.getUTCMonth() + 1; //months from 1-12
    var day = orderSubmitted.getUTCDate();
    var year = orderSubmitted.getUTCFullYear();

    var seconds = orderSubmitted.getSeconds();
    var minutes = orderSubmitted.getMinutes();
    var hour = orderSubmitted.getHours();


    var firstName = data['patientName'].split(' ').slice(0, -1).join(' ');
    var lastName = data['patientName'].split(' ').slice(-1).join(' ');

    /* Construct required payload for Medioh API */
    const payload = JSON.stringify({
        "enterpriseGUID": `${uuidv4()}`,  //element that differentiates requests, uuid ^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$ 'cc34bf3c-dfdd-4140-b88b-ff8b4f7fc486'
        "transDate": `${pad(month)}${pad(day)}${year}`,
        "transTime": `${pad(hour)}${pad(minutes)}${pad(seconds)}`,
        "sourceSystem": "HRS",
        "destinationSystem": "MEDIOH",
        
        "request": {
            "requestID": `${Date.now()}`, //unix timestamp 
            "orders": [
                {
                    "order_number": orderNumber, //order ID
                    "order_date": `${year}-${pad(month)}-${pad(day)}`,
                    "end_user_primary_id": data['patientId'],
                    "order_details": data['carrierComments'],
                    "handling_instructions": data['carrierComments'],
                    "service_level": data["serviceType"],
                    "products": [
                        {
                            "sku": data['kitTypeSelect'],
                            "quantity": 1
                        }
                    ],
                    "shipping_address": {
                        "address1": data["patientAddress1"],
                        "address2": data["patientAddress2"],
                        "city": data["patientCity"],
                        "state_province": data["patientProvince"],
                        "zip_postal_code": data["patientPostalCode"],
                        "country": "Canada",
                        "country_code": "CA",
                        "company": '', // patient company?
                        "first_name": firstName,
                        "last_name": lastName,
                        "attention": data['patientName'], //?
                        "phone": data["patientPhoneNumber"],
                        "email": data['ordererEmail']
                    }
                }
            ]
        }

    })

    var headers = new Headers();
    headers.append("Authorization", `Bearer ${prod_bearer}`);
    headers.append("X-Atlassian-Token", "no-check")
    headers.append("Content-Type", "application/json");


    var params: RequestInit = {
        headers: headers,
        method: "POST",
        body: payload,
        redirect: "follow"
    }

    var mediohTestUrl = "https://testflow.hypertec.com/api/pickPackShipOrder";
    var mediohProdUrl = "https://flow.hypertec.com/api/pickPackShipOrder"

    const res = await fetch(mediohProdUrl, params)

    if (!res.ok) {
        const message = `An error has occured: ${res.status}`;
        throw new Error(message);
    }

    return await res.json();
}

const StandardOrder: React.FC = () => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const history = useHistory();

    const validProvinces = [
        "NL",
        "PE",
        "NS",
        "NB",
        "QC",
        "ON",
        "MB",
        "SK",
        "AB",
        "BC",
        "YT",
        "NT",
        "NU"
    ]

    const {
        handleSubmit,
        control,
        setValue,
        register,
        getValues,
        formState: { errors }
    } = useForm({
        defaultValues: {
            ordererName: '',
            ordererPhoneNumber: '',
            ordererEmail: '',
            ordererOrganization: '',
            inventorySubset: '',
            virtualInstall: true,
            licenseType: '',
            kitTypeSelect: '',
            shippingSpeedSelect: 'Ground',
            patientName: '',
            patientEmail: '',
            patientPhoneNumber: '',
            patientAddress1: '',
            patientAddress2: '',
            patientCity: '',
            patientProvince: '',
            patientPostalCode: '',
            patientId: '',
            warehouseComments: '',
            carrierComments: '',
        }
    });

    /**
     *
     * @param data
     */
    const onSubmit = async (data: any) => {

        var orderSubmitted = new Date();
        var effectiveOrderDate = new Date();

        setIsButtonDisabled(true);

        //If order is submitted past 1 PM, set effective order date to next day
        if (orderSubmitted.getHours() >= 13) {
            effectiveOrderDate.setDate(orderSubmitted.getDate() + 1)
        }

        const orderNumber = createOrderNumber(effectiveOrderDate);

        if (data['kitTypeSelect'] === "HRS_PCM_InstallCallOnly"){
            createSpreadsheetRow(data, orderSubmitted, effectiveOrderDate, orderNumber).then(res => {
                alert(`Order number ${orderNumber} has been confirmed. Please copy this order number down!`)
                history.push('/thanks')
                setIsButtonDisabled(false);
            }).catch(error => {
                alert(`${error.message}: please contact support@healthrecoverysolutions.com`)
                console.log(error.message);
            });
        } else {
            createMediohOrder(data, orderSubmitted, orderNumber).then(res => {
                if(res['response']['requestStatus'] === 'REJECTED') {
                    //invalid request format
                    //invalid address
                    //invalid SKU
                    //duplicate order number
                    alert('Invalid address, order was not submitted')
                    setIsButtonDisabled(false);
                }
                else {
                    createSpreadsheetRow(data, orderSubmitted, effectiveOrderDate, orderNumber).then(res => {
                        alert(`Order number ${orderNumber} has been confirmed. Please copy this order number down!`)
                        history.push('/thanks')
                        setIsButtonDisabled(false);
                    }).catch(error => {
                        alert(`${error.message}: please contact support@healthrecoverysolutions.com`)
                        console.log(error.message);
                    });
                }
                setIsButtonDisabled(false);          
            }).catch(error => {
                console.log(error.message);
            })
        }
    };


    return (
        <IonPage>
            <Toolbar />
            <IonContent fullscreen className="ion-padding">
                <IonTitle>Standard Order Form</IonTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonCard>
                        <IonCardContent>
                            <IonItem>
                                <IonLabel>Orderer Name *</IonLabel>
                                <IonInput
                                    {...register('ordererName', {
                                        required: 'Orderer Name is a required field',
                                    })}
                                />
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="ordererName"
                                as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>Orderer Email * </IonLabel>
                                <IonInput
                                    {...register('ordererEmail', {
                                        required: 'Orderer Email is a required field',
                                        maxLength: 40,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: 'Invalid email address'
                                        }
                                    })}
                                />
                                {errors.ordererEmail && errors.ordererEmail.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="ordererEmail"
                                as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>Orderer Phone Number * </IonLabel>
                                <IonInput
                                    {...register('ordererPhoneNumber', {
                                        required: 'Orderer Phone Number is a required field',
                                        pattern: {
                                            value: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                                            message: 'Invalid phone number'
                                        }
                                    })}
                                />
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="ordererPhoneNumber"
                                as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>Orderer Organization * </IonLabel>
                                <IonInput
                                    {...register('ordererOrganization', {
                                        required: 'Orderer Organization is a required field'
                                    })}
                                />
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="ordererOrganization"
                                as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>Inventory Subset </IonLabel>
                                <IonInput
                                    {...register('inventorySubset')}
                                />
                            </IonItem>
                        </IonCardContent>
                    </IonCard>
                    <IonCard>
                        <IonCardContent>
                            <IonItem>
                                <IonLabel>Should this patient receive the PatientDirect+ Virtual Installation service?</IonLabel>
                                <Controller
                                    name="virtualInstall"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <IonCheckbox
                                                checked={field.value}
                                                onIonChange={e => {
                                                    setValue('virtualInstall', e.detail.checked);
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </IonItem>
                        </IonCardContent>
                    </IonCard>
                    <IonCard>
                        <IonCardContent>
                            <IonItem>
                                <IonLabel>Kit Type *</IonLabel>
                                <Controller
                                    render={({ field }) => (
                                        <IonSelect
                                            placeholder="Select One"
                                            value={field.value}
                                            onIonChange={e => setValue('kitTypeSelect', e.detail.value)}
                                            interface="action-sheet"
                                        >
                                            <IonSelectOption value="HRS_PCC_SM">1 - Tablet - BP Scale PulseOx - SM</IonSelectOption>
                                            <IonSelectOption value="HRS_PCC_MED">2 - Tablet - BP Scale PulseOx - MED</IonSelectOption>
                                            <IonSelectOption value="HRS_PCC_LG">3 - Tablet - BP Scale PulseOx - LG</IonSelectOption>
                                            <IonSelectOption value="HRS_PCCore">4 - Tablet - No peripherals - OS</IonSelectOption>
                                            <IonSelectOption value="HRS_PCC_NoPulseOx_SM">11 - Tablet - BP Scale - SM</IonSelectOption>
                                            <IonSelectOption value="HRS_PCC_NoPulseOx_MED">12 - Tablet - BP Scale - MED</IonSelectOption>
                                            <IonSelectOption value="HRS_PCC_NoPulseOx_LG">13 - Tablet - BP Scale - LG</IonSelectOption>
                                            <IonSelectOption value="HRS_PCC_BPonly_SM">14 - Tablet - BPonly - SM</IonSelectOption>
                                            <IonSelectOption value="HRS_PCC_BPonly_MED">15 - Tablet - BPonly - MED</IonSelectOption>
                                            <IonSelectOption value="HRS_PCC_BPonly_LG">16 - Tablet - BPonly - LG</IonSelectOption>
                                            <IonSelectOption value="HRS_PCC_PoxOnly_STD">17 - Tablet - PulseOxonly - OS</IonSelectOption>
                                            <IonSelectOption value="HRS_PCM_SM">27 - BYOD - BP Scale PulseOx - SM</IonSelectOption>
                                            <IonSelectOption value="HRS_PCM_MED">28 - BYOD - BP Scale PulseOx - MED</IonSelectOption>
                                            <IonSelectOption value="HRS_PCM_LG">29 - BYOD - BP Scale PulseOx - LG</IonSelectOption>
                                            <IonSelectOption value="HRS_PCM_NoPulseOx_SM">36 - BYOD - BP Scale - SM</IonSelectOption>
                                            <IonSelectOption value="HRS_PCM_NoPulseOx_MED">37 - BYOD - BP Scale - MED</IonSelectOption>
                                            <IonSelectOption value="HRS_PCM_NoPulseOx_LG">38 - BYOD - BP Scale - LG</IonSelectOption>
                                            <IonSelectOption value="HRS_PCM_PulseOxOnly">42 - BYOD - PulseOxonly - OS</IonSelectOption>
                                            <IonSelectOption value="HRS_PCM_BPOnly_SM">45 - BYOD - BPonly - SM</IonSelectOption>
                                            <IonSelectOption value="HRS_PCM_BPOnly_MED">46 - BYOD - BPonly - MED</IonSelectOption>
                                            <IonSelectOption value="HRS_PCM_BPOnly_LG">47 - BYOD - BPonly - LG</IonSelectOption>
                                            <IonSelectOption value="HRS_PCM_BPOnly_XL">48 - BYOD – BP only - XL</IonSelectOption>  
                                            <IonSelectOption value="HRS_PCM_InstallCallOnly">BYOD - NO HARDWARE</IonSelectOption>
                                        </IonSelect>
                                    )}
                                    control={control}
                                    name="kitTypeSelect"
                                    rules={{ required: 'Kit type is a required field' }}
                                />
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="kitTypeSelect"
                                as={<div style={{ color: 'red' }} />}
                            />
                        </IonCardContent>
                    </IonCard>
                    <IonCard>
                        <IonCardContent>
                            <IonItem>
                                <IonLabel>Shipping Speed *</IonLabel>
                                <Controller
                                    render={({ field }) => (
                                        <IonSelect
                                            placeholder="Select Shipping Speed"
                                            value={field.value}
                                            onIonChange={e => setValue('shippingSpeedSelect', e.detail.value)}
                                            interface="action-sheet"
                                        >
                                            <IonSelectOption value="Ground">Ground</IonSelectOption>

                                        </IonSelect>
                                    )}
                                    control={control}
                                    name="shippingSpeedSelect"
                                    rules={{ required: 'Shipping Speed is a required field' }}
                                />
                            </IonItem>
                            <IonItem lines='none'>
                                <IonNote>Note: selecting a speed other than Ground may result in additional charges.</IonNote>
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="kitTypeSelect"
                                as={<div style={{ color: 'red' }} />}
                            />
                        </IonCardContent>
                    </IonCard>

                    <IonCard>
                        <IonCardContent>
                            <IonCardTitle class="card-title">Patient Order Shipping Address</IonCardTitle>
                            <IonItem>
                                <IonLabel>Full Name * </IonLabel>
                                <IonInput
                                    {...register('patientName', {
                                        required: 'Full Name is a required field',
                                        maxLength: 100
                                    })}
                                />
                                {errors.patientName && errors.patientName.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                            </IonItem>
                            
                            <ErrorMessage
                                errors={errors}
                                name="patientName"
                                as={<div style={{ color: 'red' }} />}
                            />


                            <IonItem>
                                <IonLabel>Email</IonLabel>
                                <IonInput
                                    {...register('patientEmail', {
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: 'Invalid email address'
                                        },
                                        maxLength: 40
                                    })}
                                />
                                {errors.patientEmail && errors.patientEmail.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="patientEmail"
                                as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>Phone Number * </IonLabel>
                                <IonInput
                                    {...register('patientPhoneNumber', {
                                        required: 'Phone Number is a required field',
                                        pattern: {
                                            value: /^(\(\+[0-9]{2}\))?([0-9]{3}-?)?([0-9]{3})\-?([0-9]{4})(\/[0-9]{4})?$/i,
                                            message: 'Invalid phone number'
                                        }
                                    })}
                                />
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="patientPhoneNumber"
                                as={<div style={{ color: 'red' }} />}
                            />


                            <IonItem>
                                <IonLabel>Address 1 * </IonLabel>
                                <IonInput
                                    {...register('patientAddress1', {
                                        required: 'Address 1 is a required field',
                                        maxLength: 30
                                    })}
                                />
                                {errors.patientAddress1 && errors.patientAddress1.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                            </IonItem>

                            <ErrorMessage
                                errors={errors}
                                name="patientAddress1"
                                as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>Address 2 </IonLabel>
                                <IonInput
                                    {...register('patientAddress2', {
                                        maxLength: 30
                                    })}
                                />
                                {errors.patientAddress2 && errors.patientAddress2.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                            </IonItem>

                            <IonItem>
                                <IonLabel>City *</IonLabel>
                                <IonInput
                                    {...register('patientCity', {
                                        required: 'City is a required field',
                                        maxLength: 25
                                    })}
                                />
                                {errors.patientCity && errors.patientCity.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="patientCity"
                                as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>Province/Territory *</IonLabel>
                                <IonInput
                                    {...register('patientProvince', {
                                        required: 'Patient Province is a required field',
                                        pattern: {
                                            value: /^[A-Z]{2}$/i,
                                            message: 'Invalid Province code, please use two capital letters'
                                        },
                                        validate: (value, _) => validProvinces.includes(value) || "Invalid Province",
                                    })}
                                />
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="patientProvince"
                                as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>Postal Code *</IonLabel>
                                <IonInput
                                    {...register('patientPostalCode', {
                                        required: 'Postal Code is a required field',
                                        pattern: {
                                            value: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
                                            message: 'Invalid postal code'
                                        }
                                    })}
                                />
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="patientPostalCode"
                                as={<div style={{ color: 'red' }} />}
                            />

                            <IonItem>
                                <IonLabel>ClinicianConnect Patient ID</IonLabel>
                                <IonInput
                                    {...register('patientId', {
                                        required: 'ClinicianConnect Patient ID is a required field'
                                    })}
                                />
                            </IonItem>
                            <ErrorMessage
                                errors={errors}
                                name="patientId"
                                as={<div style={{ color: 'red' }} />}
                            />

                        </IonCardContent>
                    </IonCard>

                    <IonCard>
                        <IonCardContent>
                            <IonCardTitle class="card-title">
                                Additional Information
                            </IonCardTitle>
                            <IonItem>
                                <IonLabel text-wrap>Notes for Warehouse Team</IonLabel>
                                <IonInput
                                    {...register('warehouseComments', {
                                        maxLength: 35
                                    })}
                                />
                                {errors.warehouseComments && errors.warehouseComments.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                            </IonItem>
                            <IonItem>
                                <IonLabel text-wrap>Notes for Shipping Carrier</IonLabel>
                                <IonInput
                                    {...register('carrierComments', {
                                        maxLength: 35
                                    })}
                                />
                                {errors.carrierComments && errors.carrierComments.type === "maxLength" && <span style={{ color: 'red' }}>Max length exceeded</span> }
                            </IonItem>
                        </IonCardContent>
                    </IonCard>

                    <IonCard>
                        <IonCardContent>
                            <IonButton class="order-form-btn" expand="block" type="submit" disabled={isButtonDisabled}>
                                Submit Order
                            </IonButton>
                        </IonCardContent>
                    </IonCard>
                </form>
            </IonContent>
        </IonPage>
    );
};

export default StandardOrder;