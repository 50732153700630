import { IonContent, IonPage } from '@ionic/react';
import SelectOrderCount from '../components/SelectOrderCount';
import Toolbar from '../components/ToolBar';

// /order
const OrderCount: React.FC = () => {
    return (
      <IonPage>
        <Toolbar />
        <IonContent fullscreen>
            <SelectOrderCount />
        </IonContent>
      </IonPage>
    );
  };
  
  export default OrderCount;