import { IonContent, IonPage } from '@ionic/react';
import SelectOrderType from '../components/SelectOrderType';
import Toolbar from '../components/ToolBar';

// /order/single

const SingleOrderOptions: React.FC = () => {
    return (
      <IonPage>
        <Toolbar />
        <IonContent fullscreen>
            <SelectOrderType />
        </IonContent>
      </IonPage>
    );
  };
  
  export default SingleOrderOptions;