import './ToolBar.css';
import { IonCol, IonGrid, IonHeader, IonRow, IonTitle, IonToolbar } from '@ionic/react';

var logo = require('../assets/hrslogo.png');
function Toolbar() {
    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonGrid>
              <IonRow class='toolbar-row'>
                <IonCol class='toolbar-col'>
                  <a href='/'><img id='toolbar-logo' src={logo}/></a>
                </IonCol>
                <IonCol class='toolbar-col'>
                  <IonTitle id='toolbar-title'>PatientDirect Ordering Portal</IonTitle>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonHeader>
      </>
    );
  }
  export default Toolbar;
  