import { IonContent, IonPage, IonText } from '@ionic/react';
import Toolbar from '../components/ToolBar';

const TermsOfUse: React.FC = () => {
    return (
      <IonPage>
        <Toolbar />
        <IonContent>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h1><strong>Health Recovery Solutions Canada, Inc. Terms of Use</strong></h1>
                <h4><strong> Effective March 31, 2023 </strong></h4>
                <h4>ACCEPTING OUR TERMS</h4>
            </IonText>

            <p style={{ display: 'flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                By logging in to Health Recovery Solutions Canada Inc.’s (“HRS”) PatientConnect™ platform and clicking “Accept”, you confirm that you voluntarily accept the terms and conditions set forth below and such terms and conditions shall form a binding agreement between us (this “Agreement”). This Agreement permits you to utilize PatientConnect™ (the “App”, including the software program in object code form) in accordance with the terms and conditions set forth below.
                </IonText>
            </p>


            <p style={{ display: 'flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                Please carefully read these terms and conditions in their entirety before accepting. If you do not agree with these terms and conditions do not click Accept and do not download or use the App.
                </IonText>
            </p>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h4>BACKGROUND</h4>
            </IonText>

            <p style={{ display: 'flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                The App is intended for use by patients/residents, their guardians, their caregivers, their loved ones (collectively, “Client”), their healthcare professionals, and others related to care coordination (collectively, “Care Coordination Team”).
                </IonText>
            </p>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h4>IMPORTANT MEDICAL INFORMATION</h4>
            </IonText>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                It is very important that you understand and acknowledge certain information relating to the use of the App:
                </IonText>
            </p>


            <IonText style={{ textAlign: 'center' }} color='secondary'>
                <h4><strong>IMPORTANT INFORMATION</strong></h4>
            </IonText>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                 <strong>If there is a medical emergency call 911 in North America.</strong>
                </IonText>
            </p>
            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                 <strong>Do seek medical attention if you are concerned about your health or the health of the person you are caring for.</strong>
                </IonText>
            </p>
            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                 <strong>Remember that the information put into the App is not continuously monitored by medical staff and may not be seen for some time.</strong>
                </IonText>
            </p>
            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                 <strong>Never seek medical attention or advice through this App in the event of an emergency or concern.</strong>
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                    <ul>
                        <li>The purpose of the App is to allow the Care Coordination Team and Client to exchange and see health information of a non-urgent nature about Client that has been collected at home.</li>
                        <li>The App is NOT to be used to communicate about urgent matters or emergencies, as the information enter into the App may not be seen or read by medical staff right away or at all. Any information entered into the App should be discussed with a healthcare professional for proper diagnosis and treatment. The App does not give diagnoses or treatment.</li>
                        <li>The Care Coordination Team will provide the Client with instructions about how and when to use the App and what information to enter.</li>
                        <li>The App contains some information that the Client or the Care Coordination Team might find useful. This information is for education purposes only and is not a substitute for medical advice. The information provided by the App may not be accurate, up to date, or right for the Client or the Care Coordination Team.</li>
                    </ul>
                </IonText>
            </p>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h4>GRANT OF LICENSE</h4>
            </IonText>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                Upon your acceptance of this Agreement, HRS grants you a personal, non-exclusive, non-transferable, internal license to use the App. You may only use the App for personal and non-commercial purposes.
                </IonText>
            </p>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h4>RESTRICTIONS</h4>
            </IonText>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                You agree not to reverse engineer, disassemble, reverse translate, decompile or in any other manner decode the App. You shall not distribute, lease, rent, grant a security interest in, assign, or otherwise transfer the App. You shall not modify or create any derivative works of the App or merge all or any part of the App with another program. You acknowledge that the App contains confidential information and know-how and you shall not use such confidential information or know-how except to the extent necessary to exercise the rights granted to in this Agreement.
                </IonText>
            </p>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h4>TERM</h4>
            </IonText>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                This Agreement shall continue for as long as you use the App, however, your right to use the App will terminate, without notice from HRS, if you fail to comply with any of its terms or conditions. You may terminate these Terms at any time by requesting from HRS to delete your PatientConnect™ account or by deleting and stopping your use of the App. In addition to this Section, the Sections entitled Disclaimer of Warranties; Limitation of Liability, Title, and General shall continue in force even after any termination of this Agreement.
                </IonText>
            </p>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h4>UPDATES</h4>
            </IonText>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                To share improvements and new features with you, HRS may provide updates or upgrades to the App. Updates may be downloaded and installed automatically.
                </IonText>
            </p>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h4>TITLE</h4>
            </IonText>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                All right, title, and interest (including all intellectual property rights) in, to, and under the App (including all copies thereof) shall remain with HRS and its licensors.
                </IonText>
            </p>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h4>INTELLECTUAL PROPERTY</h4>
            </IonText>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                HRS and all HRS product or service names and logos are trademarks or registered trademarks of Health Recovery Solutions, Inc. in certain countries. All other company and product or service names and logos are trademarks or registered trademarks of their respective owners in certain countries.
                </IonText>
            </p>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h4>COPYRIGHT POLICY</h4>
            </IonText>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                We respect the intellectual property right of others. The App is protected by copyright laws and international copyright treaties, as well as other intellectual property laws,
                </IonText>
            </p>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY <br></br>
                EXCEPT AS MAY BE PROVIDED BY HRS TO YOU IN WRITING, THE APP IS PROVIDED “AS IS”, WITHOUT ANY REPRESENTATIONS, CONDITIONS, OR WARRANTIES OF ANY KIND. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, HRS MAKES NO WARRANTIES, REPRESENTATIONS OR GUARANTEES THAT THE APP IS SUITABLE FOR ANY USE IN ANY ENVIRONMENT REQUIRING FAIL-SAFE PERFORMANCE (INCLUDING WITHOUT LIMITATION EMERGENGY RESPONSE OR DISTRESS COMMUNICATION SYSTEMS OR AS PART OF ANY LIFE SUPPORT SERVICES) OR ANY OTHER APPLICATION IN WHICH THE FAILURE OF THE APP COULD RESULT IN DEATH OR PERSONAL INJURY. EXCEPT WITH RESPECT TO CLAIMS OF INDEMNITY, BREACH OF PRIVACY, BREACH OF SECURITY AND/OR BREACH OF THIRD PARTY INTELLECTUAL PROPERTY RIGHTS, HRS SHALL NOT HAVE ANY LIABILITY TO YOU OR ANY OTHER PERSON OR ENTITY IN CONNECTION WITH YOUR USE OF THE APP OR THIS AGREEMENT, INCLUDING FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES.

                </IonText>
            </p>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h4>SECURITY</h4>
            </IonText>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                HRS is responsible for ensuring that the information you enter into the App is secure from third party or other unauthorized access by utilizing available security safeguards. However, using the internet to transmit and store data has inherent risks and the privacy and security of the information you enter cannot be guaranteed. HRS is responsible only to the extent that that it must take all reasonable measures to ensure the security of the information and the App.
                </IonText>
            </p>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h4>EXPORT RESTRICTIONS</h4>
            </IonText>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                The App and related information are subject to export and import restrictions. Accordingly, you agree to comply with the export laws and regulations of Canada, United States and any local laws and regulations in your jurisdiction that may impact your right to export, import, or use the App or related information. The App shall not be used for any purposes prohibited by export laws and/or regulations, including, without limitation, nuclear, chemical, or biological weapons proliferation. You shall be responsible for procuring all required permissions for any subsequent export, import, or use of the App or related information.
                </IonText>
            </p>

            <IonText style={{ textAlign: 'center' }} color='primary'>
                <h4>GENERAL</h4>
            </IonText>

            <p style={{ display: 'inline-flex', justifyContent: 'center' }}>
                <IonText class='legal-doc-text'>
                This Agreement is the entire agreement between you and HRS about your use of the App. You and HRS do not waive any rights in this Agreement if you or HRS do not insist upon strict performance of any terms in this Agreement. If a court finds any term of this Agreement to be invalid or unenforceable, that term will be modified or restricted as little as possible to make it enforceable and the rest of the Agreement will remain in effect provided all essential purposes of this Agreement are intact. Any terms in this Agreement which by their reasonably intended meaning are intended to survive the termination of the Agreement will so survive. This Agreement and all related matters will be interpreted, enforced, governed by, and construed in accordance with the laws of the Province of Ontario and the applicable laws of Canada. You and HRS agree to attorn to the exclusive jurisdiction of the courts of Ontario.
                </IonText>
            </p>


            


        </IonContent>
      </IonPage>
    );
  };
  
  export default TermsOfUse;