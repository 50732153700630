import { IonButton, IonContent, IonFooter, IonGrid, IonPage, IonRow, IonTitle, IonToolbar} from '@ionic/react';
import ShippingTimelineCard from '../components/ShippingTimelineCard';
import VirtualInstallInfoCard from '../components/VirtualInstallInfoCard';
import Toolbar from '../components/ToolBar';
import './Home.css';

const Home: React.FC = () => {
  return (
    <IonPage>
      <Toolbar />
      <IonContent fullscreen>
        <IonGrid>
          <IonRow><IonTitle>Welcome to HRS PatientDirect!</IonTitle></IonRow>
        </IonGrid>      
        <ShippingTimelineCard />
        <VirtualInstallInfoCard />
        <IonRow>
            <IonButton id='begin-button' routerLink="/order/single">Place Order</IonButton>
        </IonRow>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <a href='/legal/terms'>Terms of Use</a><br></br><a href='/legal/privacy'>Privacy Policy</a>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Home;
