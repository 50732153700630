import './InfoCard.css';
import React from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonList } from '@ionic/react';


function ShippingTimelineCard() {
    return (
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>For <strong>PatientDirect</strong> orders placed by 12:00 PM (ET) daily, please refer to the shipping timelines below:</IonCardTitle>
        </IonCardHeader>
  
        <IonCardContent>
            <ul className="card-list">
                <li>
                Orders of 1-to-10 kits will ship out the same business day you submit your request.
                </li>
                <li>
                Orders of 11-to-25 kits will ship out the next business day (24 hours) after your request.
                </li>
                <li>
                Orders of 26-to-100 kits will ship out within one week (5 business days) of your request.
                </li>
                <li>
                Orders of 101 or more kits will be addressed by the Logistics team as a unique request and will communicate the timeline for delivery via email.
                </li>
            </ul>
        </IonCardContent>
        <IonList lines='none'>
            <IonItem>
            Please note that for all orders, signature is required on delivery. Contact&nbsp;<a href="mailto:support@healthrecoverysolutions.com\">support@healthrecoverysolutions.com</a> &nbsp;with any inquiries.  
            </IonItem>
            
        </IonList>
      </IonCard>
    );
  }
  export default ShippingTimelineCard;